import firebase from 'firebase'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'

// const GRAPHQL_URI = '/graphql'
// const GRAPHQL_URI = 'http://localhost:4000'
const GRAPHQL_URI = 'https://dev-graphql-manager-jbmofavj2q-uc.a.run.app'

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
})

const authLink = setContext(async(_, { headers }) => {
  let authorization

  try {
    const user = firebase.auth().currentUser

    if (user) {
      authorization = await user.getIdToken()
    }
  } catch (error) {
    console.error(error)
  }

  return {
    headers: {
      ...headers,
      authorization,
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

export { client }
