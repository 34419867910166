import * as firebase from 'firebase/app'

import 'firebase/auth'
// import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDmX_mZ0Z9wMswr1S-NRO7DMK0n1qrmDB0',
  authDomain: 'videomatic-145818.firebaseapp.com',
  databaseURL: 'https://videomatic-145818.firebaseio.com',
  projectId: 'videomatic-145818',
  storageBucket: 'videomatic-145818.appspot.com',
  messagingSenderId: '984077183507',
  appId: '1:984077183507:web:ba74287162ad98c2',
}

export function initialize() {
  firebase.initializeApp(firebaseConfig)
}
