import gql from 'graphql-tag'

export const renderRequest = gql`
  mutation RenderRequest ($input: RenditionInput!) {
    renderRequest(input: $input) {
      id
      type
      createdAt
    }
  }
`
export const removeRendition = gql`
  mutation RemoveRendition ($id: String!) {
    renditionRemove(id: $id) {
      error
      success
    }
  }
`
export const cancelRendition = gql`
  mutation CancelRendition ($id: String!) {
    renditionCancel(id: $id) {
      error
      success
    }
  }
`
