import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { ForgotForm } from '../forms/ForgotForm'

export default function RegisterPage() {
  return (
      <Container>
          <Row>
              <Col></Col>
              <Col xs={12} sm={8} md={6}>
                <h3 className="text-md-center">
                    Reset your password
                </h3>
              </Col>
              <Col></Col>
          </Row>
          <Row>
              <Col></Col>
              <Col xs={12} sm={8} md={6} lg={4}>
                <hr />
                <ForgotForm />
                <hr />
              </Col>
              <Col></Col>
          </Row>
          <Row>
              <Col></Col>
              <Col xs={12} sm={8} md={6} lg={4} className="text-md-center">
                <Link to='login'>Login to your Account</Link>
              </Col>
              <Col></Col>
          </Row>
      </Container>
  )
}
