import React, { useEffect, useRef } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { Field, Form as FinalForm } from 'react-final-form'

import { useAuth } from '../hooks/auth'

type RegisterFormData = {
  username: string,
  password: string,
  pazzword: string,
  remember: boolean
}

const initialValues: RegisterFormData = {
  username: '',
  password: '',
  pazzword: '',
  remember: true,
}

export function RegisterForm() {
  const inputEmail = useRef(null)
  const { error, loading, success, setError, submitCreate } = useAuth()

  const onSubmit = async (values: RegisterFormData) => {
    const { username, password, pazzword, remember } = values

    if (password && password.length && password === pazzword) {
      await submitCreate(username, password, remember)
    } else {
      setError('Passwords do not match, please try again.')
    }
  }

  useEffect(() => {
    (inputEmail.current! as HTMLInputElement).focus()
  }, [])

  return (
    <FinalForm { ...{ onSubmit, initialValues }} render={({ form, ...props }) => (
      <form onSubmit={props.handleSubmit}>
        <Field name='username' type='email'>
          {({ input }) => (<>
            <Form.Group controlId='usernameGroup'>
              <Form.Label>Email address</Form.Label>
              <Form.Control { ...input } ref={inputEmail} type='email' required />
            </Form.Group>
          </>)}
        </Field>

        <Field name='password' type='password'>
          {({ input }) => (<>
            <Form.Group controlId='passwordGroup'>
              <Form.Label>Password</Form.Label>
              <Form.Control { ...input } type='password' required />
            </Form.Group>
          </>)}
        </Field>

        <Field name='pazzword' type='password'>
          {({ input }) => (<>
            <Form.Group controlId='pazzwordGroup'>
              <Form.Label>Confirm password</Form.Label>
              <Form.Control { ...input } type='password' required />
            </Form.Group>
          </>)}
        </Field>

        <Field name='remember' type='checkbox'>
          {({ input, meta }) => (<>
            <Form.Group controlId='checkboxGroup'>
              <Form.Check { ...input } type='checkbox' label='Remain logged in?' />
            </Form.Group>
          </>)}
        </Field>

        <Form.Group controlId='submitGroup'>
          <Button variant='primary' type='submit' disabled={loading}>
            { loading ? 'One moment...' : 'Register' }
          </Button>
        </Form.Group>

        { error ? <Alert variant="danger">{error}</Alert> : null }
        
        { success ? <Alert variant="success">Registered successfully!</Alert> : null }
      </form>
    )}
  />
  )
}
