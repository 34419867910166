import React, { useState } from 'react'
import { Dropdown, Nav, Navbar } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
// import { LinkContainer } from 'react-router-bootstrap'

import { useAuth, useAuthState } from './hooks/auth'

const ICON_EJECT = <i style={{ color: '#555', paddingLeft: 2 }} className='fa fa-eject'></i>
const ICON_PLAY = <i style={{ color: '#555', transform: 'rotate(90deg)' }} className='fa fa-play'></i>
const LOGO_NAME = [ ICON_PLAY, 'ideo', 'm', ICON_EJECT, 'tic' ]
const MENU_NAV: MenuItem[] = [
    { to: '/', icon: 'fa fa-dashboard', title: 'Dashboard' },
    { to: '/videos', icon: 'fa fa-film', title: 'Videos' },
    { to: '/projects', icon: 'fa fa-flask', title: 'Projects' },
    { to: '/templates', icon: 'fa fa-code', title: 'Templates' },
]
// const MENU_CREATE = [
//     { handler: showPopupVideoCreate, icon: 'fa fa-film fa-fw', title: 'New Video' },
//     { handler: showPopupProjectCreate, icon: 'fa fa-flask fa-fw', title: 'New Project' },
//     { handler: showPopupTemplateCreate, icon: 'fa fa-code fa-fw', title: 'New Template' }
// ]
const MENU_USER: MenuItem[] = [
    { to: '/accounts', icon: 'fa fa-user fa-fw', title: 'Accounts' },
    { to: '/settings', icon: 'fa fa-cog fa-fw', title: 'Settings' },
]

function handleNavItemClick() {
    console.log('handleNavItemClick')
}

function renderDropdownItem(path: string) {
  return function(item: MenuItem, index: number) {
    const klass = path.includes(item.to) ? 'active' : ''

    return (
        <Dropdown.Item as={Link} to={item.to} className={klass} key={index} onClick={handleNavItemClick}>
            <i className={item.icon}></i> {item.title}
        </Dropdown.Item>
    )
  }

}

function UnauthedMenu() {
  return (
      <ul key='login' className='navbar-nav mr-auto pull-right'>
          <li className='nav-item'>
              <Link className='nav-link' to='/login'>
                  <i className='fa fa-sign-in'></i> Login
              </Link>
          </li>
      </ul>
  )
}

function AuthedDropdownMenu(props: HeaderMenuProps) {
    const { submitLogout } = useAuth()

    return (
      <Dropdown { ...{ navbar: true, alignRight: true }}>
          <Dropdown.Toggle id='menu-user-auth'>
              <i className='fa fa-user'></i> Hey
          </Dropdown.Toggle>

          <Dropdown.Menu>
              {MENU_USER.map(renderDropdownItem(props.path))}
              <Dropdown.Divider />
              <Dropdown.Item onClick={submitLogout}>
                  <i className='fa fa-sign-out fa-fw'></i> Logout
              </Dropdown.Item>
          </Dropdown.Menu>
      </Dropdown>
  )
}

function HeaderMenu(props: HeaderMenuProps) {
    return (<>
        {MENU_NAV.map((item: MenuItem, index: number) => {
            const klass = props.path === item.to ? 'active' : ''

            return (
                <Nav.Item className={klass} key={index}>
                    <Link className='nav-link' to={item.to} onClick={handleNavItemClick}>
                        <i className={item.icon}></i> {item.title}
                    </Link>
                </Nav.Item>
            )
        })}
    </>)
}

function HeaderLogo() {
  return (
      <Nav.Item onClick={handleNavItemClick}>
          <Link className='navbar-brand' to='/'>
              <small>{LOGO_NAME[0]}</small>
              <strong>{LOGO_NAME[1]}</strong>
              <strong>{LOGO_NAME[2]}</strong>
              <small>{LOGO_NAME[3]}</small>
              <strong>{LOGO_NAME[4]}</strong>
          </Link>
      </Nav.Item>
  )
}

interface HeaderMenuProps {
    path: string
}

interface MenuItem {
    to: string,
    icon: string,
    title: string
}

export default function Header() {
  const location = useLocation()
  const { user } = useAuthState()
  const [ clickedNavItem, clickNavItem ] = useState(false)
  const navbarProps = {
    //   ...(clickedNavItem ? { expanded: false } : null)
      ...{},
  }

  const handleNavToggle = () => clickNavItem(!clickedNavItem)

  return (
      <Navbar expand='lg' bg='light' variant='light'
          onToggle={handleNavToggle} { ...navbarProps }>
          <HeaderLogo />
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className='mr-auto'>
                  { user ? <HeaderMenu path={location.pathname} /> : null }
              </Nav>
              <Nav>
                  { user ? <AuthedDropdownMenu path={location.pathname} /> : <UnauthedMenu /> }
              </Nav>
          </Navbar.Collapse>
      </Navbar>
  )
}
