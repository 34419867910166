import qs from 'querystring'
import firebase from 'firebase'
import { useEffect, useState } from 'react'

export type AuthParams = {
  mode?: string,
  code?: string,
  valid: boolean
}

export const AuthTypes = {
  RESET_PASSWORD: 'resetPassword',
  RECOVER_EMAIL: 'recoverEmail',
  VERIFY_EMAIL: 'verifyEmail'
}

type Auth = firebase.auth.Auth

const { Auth } = firebase.auth

const authCallback: WeakMap<Function, number> = new WeakMap()
const authCallbacks: Function[] = []

let authCallbackIds = 0
let authIsInitialized = false
let authError: any | null = null
let authEmail: string | null = null
let authParams: AuthParams = { valid: false }
let loggedInUser: firebase.User | null = null

async function handleAuthParams(auth: Auth) {
  const parsed = parseAuthQueryString()
  const { mode, code, valid } = parsed

  if (valid) {
    authParams = parsed

    try {
      if (mode === AuthTypes.RESET_PASSWORD) {
        authEmail = await auth.verifyPasswordResetCode(code!)
        console.log({ authEmail })
      } else if (mode === AuthTypes.VERIFY_EMAIL) {
        // TODO
        // auth.applyActionCode
      } else if (mode === AuthTypes.RECOVER_EMAIL) {
        // TODO
        // https://firebase.google.com/docs/auth/custom-email-handler
      }
    } catch (error) {
      authError = error.message || error
      console.error(error)
    }
  }
}

async function handleCredentials(auth: Auth, username: string, password: string, remember: boolean, create: boolean = false) {
  await auth.setPersistence(remember ? Auth.Persistence.LOCAL : Auth.Persistence.SESSION)

  if (create) {
    await auth.createUserWithEmailAndPassword(username, password)
  } else {
    await auth.signInWithEmailAndPassword(username, password)
  }
}

async function handleUpdatePassword(auth: Auth, params: AuthParams, username: string, password: string, remember: boolean) {
  await auth.confirmPasswordReset(params.code!, password)

  if (remember) {
    await handleCredentials(auth, username, password, remember)
  }
}

async function initializeAuth() {
  const auth = firebase.auth()

  authIsInitialized = true
  
  await handleAuthParams(auth)

  auth.onAuthStateChanged(user => {
    console.log({ onAuthStateChanged: { user } })

    loggedInUser = user
    invokeAuthCallbacks(user)
  })
}

function invokeAuthCallbacks(user: firebase.User | null) {
  for (const cb of authCallbacks) {
    if (authCallback.has(cb)) {
      console.log('calling auth callback id:', authCallback.get(cb), authCallbacks.indexOf(cb))
      setTimeout(cb, 1000, user)
    } else {
      console.log('MEH', authCallbackIds, authCallbacks.length)
    }
  }
}

function parseAuthQueryString(): AuthParams {
  try {
    const search = window.location.search
    const parsed = qs.parse(search.substring(1))

    console.log('parseAuthQueryString', { parsed })

    if (parsed['mode'] && parsed['oobCode']) {
      return {
        valid: true,
        mode: parsed['mode'] as string,
        code: parsed['oobCode'] as string
      }
    }
  } catch (error) {
    console.error(error)
  }

  return { valid: false }
}

function useAsyncState() {
  const [ error, setError ] = useState<string | null>(null)
  const [ result, setResult ] = useState<any | null>(null)
  const [ loading, setLoading ] = useState(false)
  const [ success, setSuccess ] = useState(false)

  async function handleOperation(operation: Promise<any>) {
    setError(null)
    setResult(null)
    setLoading(true)
    setSuccess(false)

    try {
      setResult(await operation)
      setSuccess(true)
    } catch (error) {
      console.error(error)
      setError(error.message || JSON.stringify(error, null, 2))
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, result, success, setError, handleOperation }
}

export function useAuthState() {
  const [ loading, setLoading ] = useState(false)
  const [ currentUser, setCurrentUser ] = useState<firebase.User | null>(null)

  if (!authIsInitialized) {
    setLoading(true)
    initializeAuth()
  }

  useEffect(function() {
    console.log('useAuthState useEffect', authCallbacks.length)

    function handleAuthChange(user: firebase.User | null) {
      console.log('handleAuthChange id:', authCallback.get(handleAuthChange))
      if (!authCallback.get(handleAuthChange)) {
        return
      }
      if (currentUser !== user) {
        setCurrentUser(user)
      }
      if (loading) {
        setLoading(false)
      }
    }

    console.log('adding handleAuthChange callback', authCallbacks.indexOf(handleAuthChange))
    authCallbacks.push(handleAuthChange)
    authCallback.set(handleAuthChange, ++authCallbackIds)
    console.log('added handleAuthChange callback id:', authCallback.get(handleAuthChange), 'length', authCallbacks.length)

    if (loggedInUser !== currentUser) {
      invokeAuthCallbacks(loggedInUser)
    }

    return function() {
      const index = authCallbacks.indexOf(handleAuthChange)
      authCallback.delete(handleAuthChange)
      authCallbacks.splice(index, 1)
      console.log('removed auth callback id:', authCallback.get(handleAuthChange), ' index:', index, 'length', authCallbacks.length)
    }
  }, [ loading, currentUser ])

  return { loading, user: currentUser || loggedInUser, authError, authEmail, authParams }
}

export function useAuth() {
  const { handleOperation, ...rest } = useAsyncState()
  const { error, loading, result, success, setError } = rest
  const state = { error, loading, result, success, setError }
  const auth = firebase.auth()

  function submitReset(username: string) {
    handleOperation(auth.sendPasswordResetEmail(username))
  }

  function submitLogout() {
    handleOperation(auth.signOut())
  }

  function submitLogin(username: string, password: string, remember: boolean) {
    handleOperation(handleCredentials(auth, username, password, remember))
  }

  function submitCreate(username: string, password: string, remember: boolean) {
    handleOperation(handleCredentials(auth, username, password, remember, true))
  }

  function verifyResetCode(params: AuthParams) {
    handleOperation(auth.verifyPasswordResetCode(params.code!))
  }

  function updatePassword(params: AuthParams, username: string, password: string, remember: boolean) {
    handleOperation(handleUpdatePassword(auth, params, username, password, remember))
  }

  return { ...state, submitCreate, submitLogin, submitLogout, submitReset, verifyResetCode, updatePassword }
}
