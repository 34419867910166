import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import 'spin.js/spin.css'
import './index.css'

import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

// import reducer from './reducers'
import { ApolloProvider } from '@apollo/react-hooks'
import App from './App'
// import { StateProvider } from './state'
import { initialize } from './firebase'
import { client } from './gql'
// import App from './containers/App'
import * as serviceWorker from './serviceWorker'

const history = createBrowserHistory()

const app = (
  <Router history={history}>
    {/* <StateProvider reducer={reducer}> */}
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
    {/* </StateProvider> */}
  </Router>
)

initialize()

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
