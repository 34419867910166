import React from 'react'
import { Container, Jumbotron } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function IndexPage() {
  return (
    <Jumbotron fluid>
      <Container>
        <h1>Automatic videos</h1>
        <p>
          Videomatic renders any website into a video, yes with audio too.
        </p>
        <Link to='videos'>Go to Videos</Link>
      </Container>
    </Jumbotron>
  )
}
