import React from 'react'
import moment from 'moment'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Card, ProgressBar, ResponsiveEmbed } from 'react-bootstrap'

import { cancelRendition, removeRendition } from '../mutations'
import { MyRenditions_myRenditions } from '../queries/types/MyRenditions'
import { GetRendition_rendition } from '../queries/types/GetRendition'
import { getRendition } from '../queries'
import { Status } from '../enums'

export type RenditionCardProps = {
  rendition: MyRenditions_myRenditions | GetRendition_rendition
}

type AspectRatio = '16by9' | '4by3' | '21by9' | '1by1' | undefined

const ASPECT_RATIOS = {
  [Number(16/9).toFixed(2)]: '16by9',
  [Number(4/3).toFixed(2)]: '4by3'
}

const POLL_INTERVAL = [ Status.PENDING, Status.PROCESSING ]

const shouldPollInterval = (status: string) => POLL_INTERVAL.includes(status as Status)

function ResultProgressBar({ rendition }: RenditionCardProps) {
  const { frames, input } = rendition
  
  let totalPercent = 0

  if (frames && frames > 0) {
    try {
      const { render: { framerate, timeout } } = input
      const totalFrames = framerate * timeout
      
      totalPercent = Math.round(frames / totalFrames) * 100
    } catch (error) {
      console.error(error)
    }
  }

  return <ProgressBar now={totalPercent} label={`${totalPercent}%`} />
}

function ResultVideo({ rendition }: RenditionCardProps) {
  const { input, result } = rendition
  const [ width, height ] = input.render.size.split('x')
  const aspectRatio = Number(parseInt(width) / parseInt(height)).toFixed(2)

  return result ? (
    <ResponsiveEmbed aspectRatio={ASPECT_RATIOS[aspectRatio] as AspectRatio}>
      <video src={result.video} />
    </ResponsiveEmbed>
  ) : null
}

function RenditionButtons({ rendition }: RenditionCardProps) {
  const { id, status } = rendition
  const variables = { id }
  const isCompleted = status === Status.COMPLETED
  const willPollInterval = shouldPollInterval(status)
  const [ submitCancel, {
    data: cancelResult,
    error: cancelError,
    loading: cancelLoading
  } ] = useMutation(cancelRendition)
  const [ submitRemove, {
    data: removeResult,
    error: removeError,
    loading: removeLoading
  } ] = useMutation(removeRendition)

  if (isCompleted) {
    console.log({ removeError, removeResult })
  }

  if (willPollInterval) {
    console.log({ cancelError, cancelResult })
  }

  return (
    <>
        { isCompleted ?
          <Button className="float-right" onClick={() => submitRemove({ variables })} disabled={removeLoading}>
            Remove
          </Button> : null
        }
        { willPollInterval ?
          <Button className="float-right" onClick={() => submitCancel({ variables })} disabled={cancelLoading}>
            Cancel
          </Button> : null
        }
    </>
  )
}

export function RenditionCard({ rendition }: RenditionCardProps) {
  const { id, input, status, createdAt } = rendition
  const variables = { id }
  const pollInterval = shouldPollInterval(status) ? 1000 : 0
  const { data, error, loading } = useQuery(getRendition, { variables, pollInterval })

  console.log({ RenditionCard: { data, error, loading } })

  return (
    <Card>
      { status === Status.COMPLETED ? <ResultVideo rendition={rendition} /> : null }
      <Card.Body>
        <Card.Title>
          <h5>{input.name}</h5>
        </Card.Title>
        { status === Status.PROCESSING ? <ResultProgressBar rendition={rendition} /> : null }
        <pre>{JSON.stringify(input.render, null, 2)}</pre>
      </Card.Body>
      <Card.Footer>
        <small>Status: {status}</small>
        <br />
        <small>Created: {moment(createdAt).fromNow()}</small>
        <RenditionButtons rendition={rendition} />
      </Card.Footer>
    </Card>
  )
}
