import React, { useEffect, useRef } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { Field, Form as FinalForm } from 'react-final-form'

import { AuthParams, useAuth } from '../hooks/auth'

type UpdatePasswordFormData = {
  username: string,
  password: string,
  pazzword: string,
  remember: boolean
}

type UpdatePasswordFormProps = {
  auth: AuthParams,
  email: string
}

const initialValues: UpdatePasswordFormData = {
  username: '',
  password: '',
  pazzword: '',
  remember: true
}

export function UpdatePasswordForm(props: UpdatePasswordFormProps) {
  const inputPass = useRef(null)
  const formValues = { ...initialValues, username: props.email }
  const { error, loading, success, setError, updatePassword } = useAuth()

  const onSubmit = (values: UpdatePasswordFormData) => {
    const { password, pazzword, remember } = values

    if (password && password.length && password === pazzword) {
      updatePassword(props.auth, props.email, password, remember)
    } else {
      setError('Passwords do not match, please try again.')
    }
  }

  useEffect(() => {
    (inputPass.current! as HTMLInputElement).focus()
  }, [])

  return (
    <FinalForm { ...{ onSubmit, initialValues: formValues }} render={({ form, ...props }) => (
      <form onSubmit={props.handleSubmit}>
        <Field name='username' type='text'>
          {({ input }) => (<>
            <Form.Group controlId='usernameGroup'>
              <Form.Label>Email address</Form.Label>
              <Form.Control { ...input } disabled />
            </Form.Group>
          </>)}
        </Field>

        <Field name='password' type='password'>
          {({ input }) => (<>
            <Form.Group controlId='passwordGroup'>
              <Form.Label>New password</Form.Label>
              <Form.Control { ...input } ref={inputPass} type='password' required />
            </Form.Group>
          </>)}
        </Field>

        <Field name='pazzword' type='password'>
          {({ input }) => (<>
            <Form.Group controlId='pazzwordGroup'>
              <Form.Label>Confirm new password</Form.Label>
              <Form.Control { ...input } type='password' required />
            </Form.Group>
          </>)}
        </Field>

        <Field name='remember' type='checkbox'>
          {({ input, meta }) => (<>
            <Form.Group controlId='checkboxGroup'>
              <Form.Check { ...input } type='checkbox' label='Log me in and remain logged in?' />
            </Form.Group>
          </>)}
        </Field>

        <Form.Group controlId='submitGroup'>
          <Button variant='primary' type='submit' disabled={loading}>
            { loading ? 'One moment...' : 'Update' }
          </Button>
        </Form.Group>

        { error ? <Alert variant="danger">{error}</Alert> : null }
        
        { success ? <Alert variant="success">Password updated successfully!</Alert> : null }
      </form>
    )}
  />
  )
}
