import { useMutation } from '@apollo/react-hooks'
import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Col, Form, Modal } from 'react-bootstrap'
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form'

import { RenditionInput } from '../../types/globalTypes'
import { renderRequest } from '../mutations'

interface CreateVideoFormData {
  name: string
  source: string
  delay: number
  timeout: number
  matchPage: boolean
  enableAudio: boolean
  framerate: string
  pageOffset: string
  pageSize: string
  renderSize: string
  aspectRatio: string
}

interface LabelValue {
  label: string
  value: string
}

interface RenderSize {
  name: string
  ratio: string
  options: RenderSizeOption[]
}

interface RenderSizeOption {
  size: string
  width: number
  height: number
}

const SIZES_JSON = require('../sizes.json')

const sizes: RenderSize[] = Object.keys(SIZES_JSON)
  .map((ratio: string) => ({ ...SIZES_JSON[ratio], ratio }) as RenderSize)

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const toAspectRatioOption = ({ name, ratio }: RenderSize): LabelValue => ({ label: `${ratio} ${name}`, value: ratio })

const defaultAspectRatio = sizes[0].ratio
const defaultRenderSize = toDefaultRenderSize(defaultAspectRatio)
const optionsAspectRatio = sizes.map(toAspectRatioOption)

const initialValues: CreateVideoFormData = {
  name: '',
  source: '',
  delay: 1,
  timeout: 15,
  matchPage: true,
  enableAudio: true,
  framerate: '30',
  pageOffset: '0,0',
  pageSize: '1600x1200',
  renderSize: defaultRenderSize,
  aspectRatio: defaultAspectRatio,
}

const log = (value: object) => {
  console.log({ ...value })
  return false
}

function toInputVariables(data: CreateVideoFormData): RenditionInput {
  return {
    name: data.name,
    render: {
      size: data.renderSize,
      timeout: Number(data.timeout),
      audio: data.enableAudio,
      delay: Number(data.delay),
      framerate: parseInt(data.framerate),
      offset: data.pageOffset,
    },
    page: {
      url: data.source,
      sized: data.matchPage,
      // width?: number | null;
      // height?: number | null;
      // click?: string | null;
    },
  }
}

function toDefaultRenderSize(aspectRatio: string): string {
  const renderSize: RenderSize = SIZES_JSON[aspectRatio] as RenderSize
  const optionIndex = Math.floor(renderSize.options.length / 2)
  const { width, height } = renderSize.options[optionIndex]

  return `${width}x${height}`
}

function toRenderSizeOptions(aspectRatio: string): LabelValue[] {
  const renderSize: RenderSize = SIZES_JSON[aspectRatio] as RenderSize

  return renderSize.options.map(({ width, height, size }): LabelValue => ({
    label: `${width}x${height} ${size}`,
    value: `${width}x${height}`,
  }))
}

function renderLabelValue({ label, value }: LabelValue, index: number) {

  return <option key={index} value={value}>{label}</option>
}

function CreateVideoForm(props: object) {
  const { values } = props as FormRenderProps
  const inputName = useRef(null)
  const [ loaded, setLoaded ] = useState(false)

  useEffect(() => {
    if (!loaded) {
      (inputName.current! as HTMLInputElement).focus()
      setLoaded(true)
    }
  }, [ loaded ])

  log({ CreateVideoForm: { values } })

  return (
  <>
    <Field name='name'>
      {({ input }) => (<>
        <Form.Group controlId='nameGroup'>
          <Form.Label>Video Name</Form.Label>
          <Form.Control { ...input } ref={inputName} placeholder='e.g. Tasty rad mochaccino SVG animation' required />
        </Form.Group>
      </>)}
    </Field>

    <Field name='source'>
      {({ input }) => (<>
        <Form.Group controlId='sourceGroup'>
          <Form.Label>Source URL</Form.Label>
          <Form.Control { ...input } placeholder='e.g. http://codepen.io/user/pen/abc123' required />
        </Form.Group>
      </>)}
    </Field>

    <FormRowRenderSize { ...props } />
    <FormRowMatchSize { ...props } />
    <FormRowRenderProps { ...props } />
   </>
  )
}

function FormRowRenderSize(props: object) {
  const { values } = props as FormRenderProps
  const [ aspectRatio, changeAspectRatio ] = useState(values.aspectRatio)

  return (
    <Form.Row>
      <Form.Group as={Col} controlId='aspectRatioGroup'>
        <Field name='aspectRatio' component='select'>
          {({ input }) => (<>
            <Form.Label>Aspect Ratio</Form.Label>
            <Form.Control { ...input } as='select'>
              {optionsAspectRatio.map(renderLabelValue)}
            </Form.Control>
            <Form.Text>Select the aspect ratio to render this video at</Form.Text>
          </>)}
        </Field>
      </Form.Group>
      <Form.Group as={Col} controlId='renderSizeGroup'>
        <Field name='renderSize' component='select'>
            {({ input }) => {
              const { onChange } = input
              const options = toRenderSizeOptions(values.aspectRatio)
              if (values.aspectRatio !== aspectRatio) {
                changeAspectRatio(values.aspectRatio)
                onChange(options[0].value)
              }
              return (<>
                <Form.Label>Render Size</Form.Label>
                <Form.Control { ...input } as='select'>
                  {options.map(renderLabelValue)}
                </Form.Control>
                <Form.Text>Select the aspect ratio to render this video at</Form.Text>
              </>)
            }}
          </Field>
      </Form.Group>
    </Form.Row>
  )
}

function FormRowMatchSize(props: object) {
  const inputPageSize = useRef(null)
  const { values } = props as FormRenderProps

  useEffect(() => {
    if (!values.matchPage) {
      (inputPageSize.current! as HTMLInputElement).focus()
    }
  }, [ values.matchPage ])

  return (
    <Form.Row>
      <Form.Group as={Col} controlId='matchPageGroup'>
        <Field name='matchPage' type='checkbox'>
          {({ input, meta }) => (<>
            <Form.Label>Match Page?</Form.Label>
            <Form.Check { ...input } type='checkbox' />
            <Form.Text>Match the page size to the render size</Form.Text>
          </>)}
        </Field>
      </Form.Group>
      <Form.Group as={Col} controlId='pageSizeGroup'>
        <Field name='pageSize'>
          {({ input }) => (<>
            <Form.Label className={values.matchPage ? 'text-muted' : ''}>Page Size</Form.Label>
            <Form.Control { ...input } disabled={values.matchPage} ref={inputPageSize}
              value={values.matchPage ? values.renderSize : undefined} />
            <Form.Text className={values.matchPage ? 'text-muted' : ''}>The page size in WxH pixels</Form.Text>
          </>)}
        </Field>
      </Form.Group>
      <Form.Group as={Col} controlId='pageOffsetGroup'>
        <Field name='pageOffset'>
          {({ input }) => (<>
            <Form.Label className={values.matchPage ? 'text-muted' : ''}>Render Offset</Form.Label>
            <Form.Control { ...input } disabled={values.matchPage} />
            <Form.Text className={values.matchPage ? 'text-muted' : ''}>The page offset in X,Y pixels to render at</Form.Text>
          </>)}
        </Field>
      </Form.Group>
    </Form.Row>
  )
}

function FormRowRenderProps(props: object) {
  const { values } = props as FormRenderProps

  return (
    <Form.Row>
      <Form.Group as={Col} controlId='framerateGroup'>
        <Form.Label>Frame Rate</Form.Label>
        <Field name='framerate' type='radio'>
          {({ input }) => <Form.Check { ...input } label='30fps' value='30' type='radio' id='fps30' checked={values.framerate === '30'} inline />}
        </Field>
        <Field name='framerate' type='radio'>
          {({ input }) => <Form.Check { ...input } label='60fps' value='60' type='radio' id='fps60' checked={values.framerate === '60'} inline />}
        </Field>
        <Form.Text>The frame rate to render the video at</Form.Text>
      </Form.Group>
      <Form.Group as={Col} controlId='renderTimeGroup'>
        <Field name='timeout' type='number'>
          {({ input }) => (<>
            <Form.Label>Render Time</Form.Label>
            <Form.Control { ...input } />
            <Form.Text>The duration of the video in total seconds</Form.Text>
          </>)}
        </Field>
      </Form.Group>
      <Form.Group as={Col} controlId='delayGroup'>
        <Field name='delay' type='number'>
          {({ input }) => (<>
            <Form.Label>Render Delay</Form.Label>
            <Form.Control { ...input } />
            <Form.Text>Time in seconds to delay rendering the video</Form.Text>
          </>)}
        </Field>
      </Form.Group>
    </Form.Row>
  )
}

export function CreateVideoFormModal() {
  const [ show, setShow ] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const [ submitRender, { data, loading, error }] = useMutation(renderRequest)

  const onSubmit = async (values: CreateVideoFormData) => {
    try {
      await sleep(300)
      await submitRender({ variables: { input: toInputVariables(values) }})
      window.alert(JSON.stringify(values, null, 2))
    } catch (error) {
      console.error(error)
    }
  }

  console.log('CreateVideoFormModal', { renderRequestData: data, loading, error })

  return (
    <>
      <Button variant='info' onClick={handleShow}>Create...</Button>

      <FinalForm { ...{ onSubmit, initialValues }} render={({ form, ...props }) => (
          <Modal show={show} onHide={handleClose}>
            <form onSubmit={props.handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Create Video</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CreateVideoForm { ...props } />
                { error ? <Alert variant="danger">{error.message}</Alert> : null }
              </Modal.Body>
              <Modal.Footer>
                <Field name='enableAudio' type='checkbox'>
                  {({ input }) => (<>
                    <Form.Check { ...input } type='checkbox' label='Render audio?' id='enableAudio' inline />
                  </>)}
                </Field>
                <Button variant='secondary' onClick={handleClose} disabled={loading}>
                  Close
                </Button>
                <Button variant='primary' type='submit' disabled={loading}>
                  { loading ? 'One moment...' : 'Create' }
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      />
    </>
  )
}
