import React from 'react'
import { Alert, Container, Col, Row } from 'react-bootstrap'
import { Link, Redirect, useLocation } from 'react-router-dom'

import { UpdatePasswordForm } from '../forms/UpdatePasswordForm'

import { AuthParams, AuthTypes, useAuthState } from '../hooks/auth'

import { LocationState } from '../App'

const HEADER_TITLE = {
  [AuthTypes.RECOVER_EMAIL]: 'Recover your Account',
  [AuthTypes.RESET_PASSWORD]: 'Update your password',
  [AuthTypes.VERIFY_EMAIL]: 'Email address verified'
}

function renderAuthUI(auth: AuthParams, email: string | null, error: string | null) {
  if (error) {
    return <Alert variant="warning">{error}</Alert>
  } else if (auth && auth.mode === AuthTypes.RESET_PASSWORD) {
    return <UpdatePasswordForm auth={auth} email={email!} />
  }
  
  return null
}

export default function AuthPage() {
  const location = useLocation()
  const { user, authEmail, authError, authParams } = useAuthState()
  const { from } = (location.state || {}) as LocationState

  console.log('AuthPage', { user, authEmail, authError, authParams })

  if (user || !authParams.valid) {
    return <Redirect to={from ? from.pathname : '/'} />
  }

  return (
      <Container>
          <Row>
              <Col></Col>
              <Col xs={12} sm={8} md={6}>
                <h3 className="text-md-center">
                    {HEADER_TITLE[authParams.mode!]}
                </h3>
              </Col>
              <Col></Col>
          </Row>
          <Row>
              <Col></Col>
              <Col xs={12} sm={8} md={6} lg={4}>
                <hr />
                {renderAuthUI(authParams, authEmail, authError)}
                <hr />
              </Col>
              <Col></Col>
          </Row>
          <Row>
              <Col></Col>
              <Col xs={12} sm={8} md={6} lg={4} className="text-md-center">
                <Link to='login'>Login to your Account</Link>
              </Col>
              <Col></Col>
          </Row>
      </Container>
  )
}
