import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Alert, Container, Jumbotron, CardColumns } from 'react-bootstrap'
import { CreateVideoFormModal } from '../forms/CreateVideoForm'
import { MyRenditions_myRenditions } from '../queries/types/MyRenditions'
import { RenditionCard } from '../cards/RenditionCard'
import { myRenditions } from '../queries'
import Loader from '../widgets/Loader'

export default function VideosPage() {
  const { data, error, loading } = useQuery(myRenditions)

  console.log({ VideosPage: { data, error, loading } })

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Jumbotron fluid>
        <Container>
          <h1>Create a new video</h1>
          <p>
            Go ahead, create a new video.
          </p>
          <CreateVideoFormModal />
        </Container>
      </Jumbotron>
      <Container>
        { error ? <Alert variant="warning">{error.message}</Alert> : null }
        <CardColumns>
          {data && data.myRenditions && data.myRenditions.map((item: MyRenditions_myRenditions) => (
            <RenditionCard rendition={item} key={item.id} />
          ))}
        </CardColumns>
      </Container>
    </>
  )
}
