import React from 'react'

import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'

import { useAuthState } from './hooks/auth'

import Header from './Header'
import Auth from './pages/AuthPage'
import Index from './pages/IndexPage'
import Login from './pages/LoginPage'
import Forgot from './pages/ForgotPage'
import Videos from './pages/VideosPage'
import Register from './pages/RegisterPage'

import Loader from './widgets/Loader'

export type Location = {
  pathname: string
}

export type LocationState = {
  from: Location
}

function PrivateRoute(props: RouteProps) {
  const { children, ...rest } = props
  const { user } = useAuthState()
  const state = (location: Location):LocationState => ({ from: location })

  return (
    <Route { ...rest } render={({ location }) => user ? children : (
      <Redirect to={{ pathname: '/login', state: state(location) }} />
    )} />
  )
}

function Routes() {
  return (
    <section>
      <Switch>
        <Route exact path='/' component={Index} />
        <Route path='/auth' component={Auth} />
        <Route path='/login' component={Login} />
        <Route path='/forgot' component={Forgot} />
        <Route path='/register' component={Register} />
        <PrivateRoute path='/videos'>
          <Videos />
        </PrivateRoute>
      </Switch>
    </section>
  )
}

const App: React.FC = () => {
  const { loading } = useAuthState()

  return (
    <main>
      <Header />
      { loading ? <Loader /> : <Routes /> }
    </main>
  )
}

export default App
