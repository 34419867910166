import "./Loader.css"

import React, { useEffect, useRef } from 'react'
import { Spinner, SpinnerOptions } from 'spin.js'

const defaultLoaderProps: SpinnerOptions = {
  //   lines: 13 // The number of lines to draw
  // , length: 28 // The length of each line
  // , width: 14 // The line thickness
  // , radius: 42 // The radius of the inner circle
  // , scale: 1 // Scales overall size of the spinner
  // , corners: 1 // Corner roundness (0..1)
  // , color: "#000" // #rgb or #rrggbb or array of colors
  // , rotate: 0 // The rotation offset
  // , direction: 1 // 1: clockwise, -1: counterclockwise
  // , speed: 1 // Rounds per second
  // , zIndex: 2e9 // The z-index (defaults to 2000000000)
  // , className: "spinner" // The CSS class to assign to the spinner
  // , top: "50%" // Top position relative to parent
  // , left: "50%" // Left position relative to parent
  // , shadow: false // Whether to render a shadow
  // , position: "absolute", // Element positioning
}

export default function Loader(props: SpinnerOptions) {
  const loaderRef = useRef(null)

  const spinner = new Spinner({ ...defaultLoaderProps, ...props })

  useEffect(() => {
    const node = loaderRef.current! as HTMLElement

    spinner.spin(node)

    return () => {
      spinner.stop()
    }
  })

  return (
    <div className="loader-spinner" ref={loaderRef}></div>
  )
}
