import React, { useEffect, useRef } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { Field, Form as FinalForm } from 'react-final-form'

import { useAuth } from '../hooks/auth'

type ForgotFormData = {
  username: string
}

const initialValues: ForgotFormData = {
  username: ''
}

export function ForgotForm() {
  const inputEmail = useRef(null)
  const { error, loading, success, submitReset } = useAuth()

  const onSubmit = async (values: ForgotFormData) => {
    const { username } = values
    await submitReset(username)
  }

  useEffect(() => {
    (inputEmail.current! as HTMLInputElement).focus()
  }, [])

  return (
    <FinalForm { ...{ onSubmit, initialValues }} render={({ form, ...props }) => (
      <form onSubmit={props.handleSubmit}>
        <Field name='username' type='email'>
          {({ input }) => (<>
            <Form.Group controlId='usernameGroup'>
              <Form.Label>Email address</Form.Label>
              <Form.Control { ...input } ref={inputEmail} type='email' required />
            </Form.Group>
          </>)}
        </Field>

        <Form.Group controlId='submitGroup'>
          <Button variant='primary' type='submit' disabled={loading}>
            { loading ? 'One moment...' : 'Request' }
          </Button>
        </Form.Group>

        { error ? <Alert variant="danger">{error}</Alert> : null }
        
        { success ? <Alert variant="success">
          <p>We've sent you an email, follow instructions there to reset your password.</p>
        </Alert> : null }
      </form>
    )}
  />
  )
}
