import gql from 'graphql-tag'

export const getRendition = gql`
query GetRendition($id: String!) {
  rendition(id: $id) {
    id
    uid
    type
    error
    frames
    size
    time
    status
    createdAt
    updatedAt
    completedAt
    result {
      elapsed
      report
      video
    }
    input {
      name
      page {
        url
      }
      render {
        size
        timeout
        framerate
      }
    }
  }
}
`

export const myRenditions = gql`
query MyRenditions {
  myRenditions {
    id
    uid
    type
    error
    frames
    size
    time
    status
    createdAt
    updatedAt
    completedAt
    result {
      elapsed
      report
      video
    }
    input {
      name
      page {
        url
      }
      render {
        size
        timeout
        framerate
      }
    }
  }
}
`
