import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Link, Redirect, useLocation } from 'react-router-dom'

import { RegisterForm } from '../forms/RegisterForm'

import { useAuthState } from '../hooks/auth'

import { LocationState } from '../App'

export default function RegisterPage() {
  const location = useLocation()
  const { user } = useAuthState()
  const { from } = (location.state || {}) as LocationState

  if (user) {
    return <Redirect to={from ? from.pathname : '/'} />
  }

  return (
      <Container>
          <Row>
              <Col></Col>
              <Col xs={12} sm={8} md={6}>
                <h3 className="text-md-center">
                    Register a new Account
                </h3>
              </Col>
              <Col></Col>
          </Row>
          <Row>
              <Col></Col>
              <Col xs={12} sm={8} md={6} lg={4}>
                <hr />
                <RegisterForm />
                <hr />
              </Col>
              <Col></Col>
          </Row>
          <Row>
              <Col></Col>
              <Col xs={12} sm={8} md={6} lg={4} className="text-md-center">
                <Link to='login'>Login to your Account</Link>
              </Col>
              <Col></Col>
          </Row>
      </Container>
  )
}
